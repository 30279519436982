:root {
}

$black_color: #223331;
$grey_color: #8B8B8B;
$white_color: #ffffff;
$semi_transparent_white_color: rgba(255, 255, 255, 0.28);
$green_color: #2B877E;
$light_green_color: #0EC7B3;
$orange_color: #FE6238;
