.header {
  background-color: transparent;
  position: fixed;
  width: 100%;
  inset-block-start: 0;
  z-index: 999;
  transition: $transition_base;
  display: flex;
  align-items: center;
  padding: 16px 0;
  min-height: 60px;
}

.header--sticky {
  background-color: $white_color;
  transition: $transition_base;
  box-shadow: 0 14px 44px 0 rgba(34, 51, 49, .1);
}

.hamburger {
  display: none;
  visibility: hidden;
}

.header__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__menus {
  display: flex;
  align-items: center;
}

.header__nav-menu {
  color: $grey_color;
  font-size: 16px;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  padding-inline-end: 179px;
}

.header__nav-menu-item a {
  transition: $transition_base;
}

.header__nav-menu-item a:hover {
  color: $black_color;
  transition: $transition_base;
}

.header__nav-actions {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.header__nav-actions .button {
  min-height: unset;
  max-height: 36px;
  padding: 8px 24px;
  font-size: 16px;
}

.lang-menu {
  margin: 0;
  padding: 0;
  padding-block-end: 7px;
  color: $grey_color;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.lang-item {
  position: relative;
  cursor: pointer;
}

.lang-item::after {
  content: '';
  background-image: url(../../assets/img/ic-dropdown.svg);
  width: 24px;
  height: 24px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(50%);
  position: relative;
  inset-block-end: 6px;
}

.lang-menu__sub-menu {
  padding: 0;
  position: absolute;
  inset-block-start: 24px;
  inset-inline-start: 0;
  border-radius: 5px;
  transform-origin: 0 0;
  transform: scaleY(0);
  visibility: hidden;
  transition: $transition_base;
  overflow: hidden;
  z-index: 9999;
}

.lang-menu:hover .lang-menu__sub-menu {
  transform-origin: 0 0;
  transform: scaleY(1);
  visibility: visible;
  transition: $transition_base;
  padding-top: 10px;
}

.lang-menu__sub-item a {
  display: inline-block;
  font-family: inherit;
  padding: 8px;
  background: $grey_color;
  transition: $transition_base;
  color: $white_color;
  width: 100%;
}

.lang-menu__sub-item:hover a {
  background: darken($grey_color, 20%);
  transition: $transition_base;
}

// Media
@media (max-width: 1199px) {
  .header__nav-menu {
    padding-inline-end: 50px;
  }
}

@media (max-width: 991px) {
  .hamburger {
    order: 1;
  }

  .header__menus {
    visibility: hidden;
    transition: $transition_base;
    transform: scaleY(0);
    position: absolute;
    inset-block-start: 0;
    background-color: $white_color;
    left: 0;
    right: 0;
    box-shadow: 0 49px 55px 0 rgba(34,51,49,.05);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-block-start: 80px;
    opacity: 0;
    z-index: -1;
    transform-origin: 0 0;
  }

  .nav-menus--opened {
    visibility: visible;
    transform: scaleY(1);
    opacity: 1;
    transition: $transition_base;
  }

  .header__nav-menu,
  .header__nav-actions {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0;
  }

  .lang-menu {
    padding-block-start: 32px;
  }

  .lang-menu__sub-menu,
  .lang-menu:hover .lang-menu__sub-menu {
    visibility: visible;
    transform: none;
    padding: 0;
    position: static;
  }

  .lang-menu__sub-item a,
  .lang-menu__sub-item a:hover {
    background: none;
    color: $grey_color;
    padding: 0;
    font-weight: 500;
  }

  .lang-menu__sub-menu::before {
    content: '';
    display: block;
    width: 1px;
    height: 14px;
    background-color: $grey_color;
    position: absolute;
    inset-inline-start: 50%;
    inset-block-start: 4px;
  }

  .lang-item::after {
    content: none;
  }

  .lang-item {
    display: flex;
    gap: 30px;
  }

  .lang-item.active-lang {
    color: $black_color;
  }
}

@media (max-width: 576px) {
  .header {
    padding: 14px 0;
  }

  .logo {
    //width: 32px;
    display: inline-flex;
    align-items: center;
  }

  .header__menus {
    padding-block-end: 54px;
  }

  //.header__nav-menu {
  //  padding-inline-start: 15px;
  //}
}

@media (max-width: 991px) {
  .hamburger {
    display: inline-block;
    visibility: visible;
  }
}

@media (max-width: 480px) {
  .header__nav-actions {
    column-gap: 12px;
  }
}

//@media (max-width: 420px) {
//  .header__nav-menu {
//    padding-inline-end: 150px;
//  }
//}
