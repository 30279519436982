.products {
  padding-block-end: 64px;
}

.products__title {
  text-align: center;
}

.products__types {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-block-start: 47px;
}

.products__product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block-start: 68px;
  padding-block-end: 56px;
  background-color: $semi_transparent_white_color;
  border-radius: 64px;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05),20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  flex-grow: 1;
}

.products__product-img {
  width: 320px;
  height: 310px;
}

.products__product-img > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.products__product-title {
  margin-block-start: 3px;
}

.products__product-desc {
  margin-block-start: 12px;
  margin-block-end: 24px;
}

@media (max-width: 768px) {
  .products__types {
    flex-wrap: wrap;
    justify-content: center;
  }
}
