.get-start {
  padding-block-end: 170px;
}

.get-start__title {
  text-align: center;
}

.get-start__list {
  padding: 56px 142px;
  margin: 0;
  margin-block-start: 56px;
  list-style: none;
  background-color: $semi_transparent_white_color;
  border-radius: 64px;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05),20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.get-start__button {
  margin: 0 auto;
  margin-block-start: 32px;
  max-width: 390px;
  display: flex;
}

@media (max-width: 991px) {
  .get-start__list {
    padding: 32px 54px;
    gap: 12px;
  }
}
