.class-color {
  color: lightcoral;
  font-family: monospace;
}
.class-name-color {
  color: yellowgreen;
  font-family: monospace;
  line-height: 3em;
}

@import 'components/typography';
@import 'components/buttons';
@import 'components/fields-and-forms';
@import 'components/modal';
@import 'components/hamburger-menu';
