.triggers__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  width: 100%;
  justify-content: center;
  text-align: center;
  background-color: $semi_transparent_white_color;
  border-radius: 64px;
  padding: 74px 0;
  margin: 0;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05),20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
}

.triggers__item-title {
  color: $orange_color;
}

.triggers__item {
  position: relative;
}

.triggers__item:not(:last-child)::after {
  content: '';
  display: block;
  width: 1px;
  height: 140px;
  inset-block-start: 50%;
  transform: translateY(-50%);
  background-color: $light_green_color;
  position: absolute;
  inset-inline-end: 0;
}

@media (max-width: 767px) {
  .triggers__list {
    padding: 44px 0;
  }

  .triggers__item:not(:last-child)::after {
    height: 100px;
  }

  .triggers__item {
    padding: 0 10px;
  }

  .triggers__item-text {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .triggers__list {
    grid-template-columns: 1fr;
    padding: 20px;
    gap: 50px;
  }

  .triggers__item:not(:last-child)::after {
    height: 1px;
    width: 100px;
    inset-block-start: auto;
    inset-block-end: -25px;
    inset-inline-start: 50%;
    transform: translate(-50%, 0);
    background-color: #0ec7b3;
    position: absolute;
  }
}
