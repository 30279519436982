.trade {
  padding-block-end: 64px;
}

.trade__title {
  text-align: center;
}

.trade__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  padding-block-start: 56px;
}

.trade__img {
  width: 590px;
  height: 544px;
  flex-grow: 1;
}

.trade__img > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.trade__list {
  flex-grow: 1;
  list-style: none;
  counter-reset: trade-counter;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0;
}

.trade__item:not(:last-of-type) {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 592px;
  background-color: $semi_transparent_white_color;
  border-radius: 64px;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05),20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  padding: 32px 16px;
  text-align: center;
}

.trade__item--button {
  margin-block-start: 8px;
}

.trade__item--button .button {
  width: 100%;
}

.trade__item-number {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 48px;
}

.trade__item-number::before {
  content: counter(trade-counter);
  counter-increment: trade-counter;
  position: absolute;
  @extend .second-title;
  color: $orange_color;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
}

.trade__item-title {
  margin-block-start: 12px;
  margin-block-end: 8px;
}

@media (max-width: 768px) {
  .trade__img {
    display: none;
  }
}
