.calendar {
  padding-block-end: 64px;
}

.calendar__title {
  text-align: center;
  margin-block-end: 56px;
}

.calendar__wrapper {
  border-radius: 64px;
  overflow: hidden;
  padding: 40px;
  background: $white_color;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05),20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  height: 591px;
}
