.slider {
  padding-block-start: 64px;
}

.slider__title {
  text-align: center;
  max-width: 997px;
  margin: 0 auto;
  margin-block-end: 54px;
}

.slider__list {
  padding: 0;
  margin: 0;
  padding-block-end: 64px;
  cursor: grab;
  user-select: none;
}

.slider__item-wrap {
  background-color: $semi_transparent_white_color;
  border-radius: 64px;
  min-height: 280px;
  padding: 54px 32px;
  text-align: center;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05),20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
}

.slider__item-ic {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.slider__item-ic > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.slider__item-title {
  color: $black_color;
}

.slider__item-text {
  margin-block-start: 12px;
}
