.about-hero {
  background-image: url(../../assets/img/about-hero.png);
}

.times {
  padding-block-start: 65px;
  padding-block-end: 64px;
}

.times__title {
  text-align: center;
  margin-block-end: 54px;
}
