.button {
  color: $white_color;
  border-radius: 40px;
  min-height: 70px;
  padding: 24px 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $light_green_color;
  cursor: pointer;
  font-family: inherit;
  font-weight: 700;
  font-size: clamp(16px, 3vw, 20px);
  line-height: normal;
  transition: $transition_base;
  text-decoration: none;
  box-shadow: 10px 10px 20px 0px rgba(43, 135, 126, 0.15),-10px -10px 20px 0px rgba(100, 252, 236, 0.15);
}

.button--orange {
  border: 1px solid $orange_color;
  background-color: transparent;
  color: $orange_color;
  box-shadow: none;
}

.button--transparent {
  border: 1px solid $light_green_color;
  background-color: transparent;
  color: $light_green_color;
  box-shadow: none;
}

.button:hover {
  transform: scale(1.01);
  transition: $transition_base;
}

.button:active {
  transform: translate(2px, 1px) scale(0.99);
  transition: none;
}

@media (max-width: 768px) {
  .button {
    min-height: 45px;
    padding: 12px 20px;
  }
}
