html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  background-color: #ffffff;
  color: #223331;
  margin: 0;
  padding: 0;
  font-family: "Gilroy", sans-serif;
  /* Don't forget about fallback font/family */
  font-size: 20px;
  line-height: normal;
  min-height: 100vh;
  position: relative;
  background-image: url(../../assets/img/body-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

#top {
  opacity: 0;
  visibility: hidden;
  height: 0;
  font-size: 0;
}

nav ul {
  list-style: none;
}

.logo {
  display: inline-block;
  height: auto;
}

.logo > img {
  object-fit: contain;
}

img {
  max-width: 100%;
  height: auto;
}

nav a {
  text-decoration: none;
  color: inherit;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

a.button:focus,
a.button:focus-visible {
  outline: 1px solid #b1e6e1;
}

.nav-menu {
  margin: 0;
  padding: 0;
}

.to-top-button {
  position: fixed;
  inset-block-end: 50px;
  inset-inline-end: 50px;
  display: block;
  background-image: url(../../assets/img/ic-top-arrow.svg);
  background-position: center center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  background-color: #0EC7B3;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transition: ease-in-out 0.3s;
}

.to-top-button--visible {
  visibility: visible;
  opacity: 1;
  transition: ease-in-out 0.3s;
}

.visibility-hidden {
  visibility: hidden;
  opacity: 0;
}

@media (max-width: 576px) {
  .to-top-button {
    width: 30px;
    height: 30px;
    inset-block-end: 20px;
    inset-inline-end: 10px;
    background-size: 70%;
  }
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  src: local("Gilroy"), url("../../assets/fonts/Gilroy-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gilroy";
  font-display: swap;
  src: local("Gilroy"), url("../../assets/fonts/Gilroy-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
::-webkit-input-placeholder {
  font-family: "Gilroy";
}

::-moz-placeholder {
  font-family: "Gilroy";
}

:-ms-input-placeholder {
  font-family: "Gilroy";
}

:-moz-placeholder {
  font-family: "Gilroy";
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

.container,
.container-fluid {
  width: 100%;
  margin-inline-start: auto;
  margin-inline-end: auto;
  padding: 0 15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 976px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
.class-color {
  color: lightcoral;
  font-family: monospace;
}

.class-name-color {
  color: yellowgreen;
  font-family: monospace;
  line-height: 3em;
}

.first-title {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-size: clamp(36px, 5vw, 64px);
  line-height: normal;
  font-weight: 700;
  color: #2B877E;
  margin: 0;
}

.second-title, .trade__item-number::before {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-size: clamp(28px, 3vw, 40px);
  line-height: normal;
  font-weight: 700;
  color: #2B877E;
  margin: 0;
}

.third-title {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-size: clamp(26px, 3vw, 32px);
  line-height: normal;
  font-weight: 700;
  color: #223331;
  margin: 0;
}

.small-title {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-size: 20px;
  letter-spacing: 0.2px;
  font-weight: 700;
  line-height: normal;
  color: #2B877E;
  margin: 0;
}

.main-text {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: clamp(16px, 2vw, 20px);
  line-height: normal;
  color: #8B8B8B;
  margin: 0;
}

.small-text {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: normal;
  color: #8B8B8B;
  margin: 0;
}

.white-text {
  color: #ffffff;
}

.button {
  color: #ffffff;
  border-radius: 40px;
  min-height: 70px;
  padding: 24px 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #0EC7B3;
  cursor: pointer;
  font-family: inherit;
  font-weight: 700;
  font-size: clamp(16px, 3vw, 20px);
  line-height: normal;
  transition: ease-in-out 0.3s;
  text-decoration: none;
  box-shadow: 10px 10px 20px 0px rgba(43, 135, 126, 0.15), -10px -10px 20px 0px rgba(100, 252, 236, 0.15);
}

.button--orange {
  border: 1px solid #FE6238;
  background-color: transparent;
  color: #FE6238;
  box-shadow: none;
}

.button--transparent {
  border: 1px solid #0EC7B3;
  background-color: transparent;
  color: #0EC7B3;
  box-shadow: none;
}

.button:hover {
  transform: scale(1.01);
  transition: ease-in-out 0.3s;
}

.button:active {
  transform: translate(2px, 1px) scale(0.99);
  transition: none;
}

@media (max-width: 768px) {
  .button {
    min-height: 45px;
    padding: 12px 20px;
  }
}
.is-visible {
  visibility: visible;
  opacity: 1;
  transition: ease-in-out 0.3s;
  display: grid;
}

.hamburger {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.9;
}

.hamburger.is-active:hover {
  opacity: 0.9;
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #223331;
}

.hamburger-box {
  width: 20px;
  height: 12px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 20px;
  height: 1px;
  background-color: #223331;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -5px;
}

.hamburger-inner::after {
  bottom: -5px;
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
  top: -10px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner {
  bottom: -3px;
  transition: ease-in-out 0.3s;
}

.hamburger--collapse .hamburger-inner {
  transition: ease-in-out 0.3s;
}

.hamburger__inner-text {
  display: inline-block;
  color: #223331;
  margin-inline-start: 24px;
  font-family: inherit;
  text-transform: uppercase;
  font-size: 18px;
}

@media (max-width: 480px) {
  .hamburger__inner-text {
    margin-inline-start: 10px;
  }
}
.header {
  background-color: transparent;
  position: fixed;
  width: 100%;
  inset-block-start: 0;
  z-index: 999;
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  padding: 16px 0;
  min-height: 60px;
}

.header--sticky {
  background-color: #ffffff;
  transition: ease-in-out 0.3s;
  box-shadow: 0 14px 44px 0 rgba(34, 51, 49, 0.1);
}

.hamburger {
  display: none;
  visibility: hidden;
}

.header__nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__menus {
  display: flex;
  align-items: center;
}

.header__nav-menu {
  color: #8B8B8B;
  font-size: 16px;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  padding-inline-end: 179px;
}

.header__nav-menu-item a {
  transition: ease-in-out 0.3s;
}

.header__nav-menu-item a:hover {
  color: #223331;
  transition: ease-in-out 0.3s;
}

.header__nav-actions {
  display: flex;
  align-items: center;
  column-gap: 16px;
}

.header__nav-actions .button {
  min-height: unset;
  max-height: 36px;
  padding: 8px 24px;
  font-size: 16px;
}

.lang-menu {
  margin: 0;
  padding: 0;
  padding-block-end: 7px;
  color: #8B8B8B;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}

.lang-item {
  position: relative;
  cursor: pointer;
}

.lang-item::after {
  content: "";
  background-image: url(../../assets/img/ic-dropdown.svg);
  width: 24px;
  height: 24px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateY(50%);
  position: relative;
  inset-block-end: 6px;
}

.lang-menu__sub-menu {
  padding: 0;
  position: absolute;
  inset-block-start: 24px;
  inset-inline-start: 0;
  border-radius: 5px;
  transform-origin: 0 0;
  transform: scaleY(0);
  visibility: hidden;
  transition: ease-in-out 0.3s;
  overflow: hidden;
  z-index: 9999;
}

.lang-menu:hover .lang-menu__sub-menu {
  transform-origin: 0 0;
  transform: scaleY(1);
  visibility: visible;
  transition: ease-in-out 0.3s;
  padding-top: 10px;
}

.lang-menu__sub-item a {
  display: inline-block;
  font-family: inherit;
  padding: 8px;
  background: #8B8B8B;
  transition: ease-in-out 0.3s;
  color: #ffffff;
  width: 100%;
}

.lang-menu__sub-item:hover a {
  background: #585858;
  transition: ease-in-out 0.3s;
}

@media (max-width: 1199px) {
  .header__nav-menu {
    padding-inline-end: 50px;
  }
}
@media (max-width: 991px) {
  .hamburger {
    order: 1;
  }

  .header__menus {
    visibility: hidden;
    transition: ease-in-out 0.3s;
    transform: scaleY(0);
    position: absolute;
    inset-block-start: 0;
    background-color: #ffffff;
    left: 0;
    right: 0;
    box-shadow: 0 49px 55px 0 rgba(34, 51, 49, 0.05);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-block-start: 80px;
    opacity: 0;
    z-index: -1;
    transform-origin: 0 0;
  }

  .nav-menus--opened {
    visibility: visible;
    transform: scaleY(1);
    opacity: 1;
    transition: ease-in-out 0.3s;
  }

  .header__nav-menu,
.header__nav-actions {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0;
  }

  .lang-menu {
    padding-block-start: 32px;
  }

  .lang-menu__sub-menu,
.lang-menu:hover .lang-menu__sub-menu {
    visibility: visible;
    transform: none;
    padding: 0;
    position: static;
  }

  .lang-menu__sub-item a,
.lang-menu__sub-item a:hover {
    background: none;
    color: #8B8B8B;
    padding: 0;
    font-weight: 500;
  }

  .lang-menu__sub-menu::before {
    content: "";
    display: block;
    width: 1px;
    height: 14px;
    background-color: #8B8B8B;
    position: absolute;
    inset-inline-start: 50%;
    inset-block-start: 4px;
  }

  .lang-item::after {
    content: none;
  }

  .lang-item {
    display: flex;
    gap: 30px;
  }

  .lang-item.active-lang {
    color: #223331;
  }
}
@media (max-width: 576px) {
  .header {
    padding: 14px 0;
  }

  .logo {
    display: inline-flex;
    align-items: center;
  }

  .header__menus {
    padding-block-end: 54px;
  }
}
@media (max-width: 991px) {
  .hamburger {
    display: inline-block;
    visibility: visible;
  }
}
@media (max-width: 480px) {
  .header__nav-actions {
    column-gap: 12px;
  }
}
.hero {
  background-image: url(../../assets/img/home-hero.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  min-height: 800px;
  position: relative;
}

.hero__content {
  position: absolute;
  inset-block-start: 50%;
  transform: translateY(-50%);
  max-width: 510px;
}

.hero__desc {
  margin: 24px 0;
  color: #223331;
}

.hero__content-actions {
  display: flex;
  gap: 24px;
}

@media (max-width: 1199px) {
  .hero {
    background-size: 40%;
    background-position: right center;
  }
}
@media (max-width: 768px) {
  .hero {
    min-height: 500px;
    background-position: 125% 60%;
  }
}
@media (max-width: 576px) {
  section.hero {
    background-image: none;
    padding: 120px 0;
    padding-block-start: 180px;
  }

  .hero__content {
    position: static;
    transform: none;
    text-align: center;
    width: 100%;
  }

  .hero__content-actions {
    gap: 10px;
    flex-direction: column;
  }
}
.triggers__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style: none;
  width: 100%;
  justify-content: center;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 64px;
  padding: 74px 0;
  margin: 0;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05), 20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
}

.triggers__item-title {
  color: #FE6238;
}

.triggers__item {
  position: relative;
}

.triggers__item:not(:last-child)::after {
  content: "";
  display: block;
  width: 1px;
  height: 140px;
  inset-block-start: 50%;
  transform: translateY(-50%);
  background-color: #0EC7B3;
  position: absolute;
  inset-inline-end: 0;
}

@media (max-width: 767px) {
  .triggers__list {
    padding: 44px 0;
  }

  .triggers__item:not(:last-child)::after {
    height: 100px;
  }

  .triggers__item {
    padding: 0 10px;
  }

  .triggers__item-text {
    font-size: 14px;
  }
}
@media (max-width: 576px) {
  .triggers__list {
    grid-template-columns: 1fr;
    padding: 20px;
    gap: 50px;
  }

  .triggers__item:not(:last-child)::after {
    height: 1px;
    width: 100px;
    inset-block-start: auto;
    inset-block-end: -25px;
    inset-inline-start: 50%;
    transform: translate(-50%, 0);
    background-color: #0ec7b3;
    position: absolute;
  }
}
.slider {
  padding-block-start: 64px;
}

.slider__title {
  text-align: center;
  max-width: 997px;
  margin: 0 auto;
  margin-block-end: 54px;
}

.slider__list {
  padding: 0;
  margin: 0;
  padding-block-end: 64px;
  cursor: grab;
  user-select: none;
}

.slider__item-wrap {
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 64px;
  min-height: 280px;
  padding: 54px 32px;
  text-align: center;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05), 20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
}

.slider__item-ic {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.slider__item-ic > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.slider__item-title {
  color: #223331;
}

.slider__item-text {
  margin-block-start: 12px;
}

.products {
  padding-block-end: 64px;
}

.products__title {
  text-align: center;
}

.products__types {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding-block-start: 47px;
}

.products__product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block-start: 68px;
  padding-block-end: 56px;
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 64px;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05), 20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  flex-grow: 1;
}

.products__product-img {
  width: 320px;
  height: 310px;
}

.products__product-img > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.products__product-title {
  margin-block-start: 3px;
}

.products__product-desc {
  margin-block-start: 12px;
  margin-block-end: 24px;
}

@media (max-width: 768px) {
  .products__types {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.calendar {
  padding-block-end: 64px;
}

.calendar__title {
  text-align: center;
  margin-block-end: 56px;
}

.calendar__wrapper {
  border-radius: 64px;
  overflow: hidden;
  padding: 40px;
  background: #ffffff;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05), 20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  height: 591px;
}

.trade {
  padding-block-end: 64px;
}

.trade__title {
  text-align: center;
}

.trade__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
  padding-block-start: 56px;
}

.trade__img {
  width: 590px;
  height: 544px;
  flex-grow: 1;
}

.trade__img > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.trade__list {
  flex-grow: 1;
  list-style: none;
  counter-reset: trade-counter;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0;
  padding: 0;
}

.trade__item:not(:last-of-type) {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 592px;
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 64px;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05), 20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  padding: 32px 16px;
  text-align: center;
}

.trade__item--button {
  margin-block-start: 8px;
}

.trade__item--button .button {
  width: 100%;
}

.trade__item-number {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 48px;
}

.trade__item-number::before {
  content: counter(trade-counter);
  counter-increment: trade-counter;
  position: absolute;
  color: #FE6238;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
}

.trade__item-title {
  margin-block-start: 12px;
  margin-block-end: 8px;
}

@media (max-width: 768px) {
  .trade__img {
    display: none;
  }
}
.get-start {
  padding-block-end: 170px;
}

.get-start__title {
  text-align: center;
}

.get-start__list {
  padding: 56px 142px;
  margin: 0;
  margin-block-start: 56px;
  list-style: none;
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 64px;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05), 20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.get-start__button {
  margin: 0 auto;
  margin-block-start: 32px;
  max-width: 390px;
  display: flex;
}

@media (max-width: 991px) {
  .get-start__list {
    padding: 32px 54px;
    gap: 12px;
  }
}
.about-hero {
  background-image: url(../../assets/img/about-hero.png);
}

.times {
  padding-block-start: 65px;
  padding-block-end: 64px;
}

.times__title {
  text-align: center;
  margin-block-end: 54px;
}

.trading-hero {
  background-image: url(../../assets/img/trading-hero.png);
}

.tariffs {
  padding-block-start: 64px;
}

.tariffs__title {
  text-align: center;
}

.tariffs__plans {
  margin: 0;
  padding: 0;
  list-style: none;
}

.plans {
  padding-block-start: 54px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
}

.plans__item {
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 64px;
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05), 20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  padding: 48px 39px;
  padding-block-end: 54px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plans__item-title {
  text-align: center;
  color: #FE6238;
}

.plans__services {
  margin: 0;
  padding: 0;
  padding-block-start: 24px;
  padding-block-end: 36px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
}

.plans__service--green {
  color: #0EC7B3;
}

.plans__item-price {
  text-align: center;
  margin-block-end: 16px;
}

@media (max-width: 991px) {
  .plans {
    gap: 32px;
  }
}
.study-hero {
  background-image: url(../../assets/img/study-hero.png);
}

.faq {
  padding: 64px 0;
}

.faq__title {
  text-align: center;
}

.faq__tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-block-start: 54px;
  padding-block-end: 36px;
}

.tab-button {
  min-width: 288px;
}

.tab-button--is-active {
  background-color: #FE6238;
  color: #ffffff;
  transition: ease-in-out 0.3s;
  box-shadow: 10px 10px 20px 0 rgba(43, 135, 126, 0.15), -10px -10px 20px 0 rgba(100, 252, 236, 0.15);
}

.accordion {
  width: 100%;
  display: none;
}

.accordion--is-active {
  display: block;
}

.accordion__container {
  transition: ease-in-out 0.3s;
  background-color: rgba(255, 255, 255, 0.28);
  box-shadow: -20px -20px 40px 0px rgba(118, 251, 237, 0.05), 20px 20px 20px 0px rgba(14, 199, 179, 0.05);
  backdrop-filter: blur(7px);
  border-radius: 64px;
  overflow: hidden;
  margin-block-end: 16px;
}

.accordion__question {
  appearance: none;
  list-style: none;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  padding: 0 54px;
  padding-inline-end: 100px;
  color: #8B8B8B;
  min-height: 86px;
  display: flex;
  align-items: center;
  position: relative;
}

.accordion__question::after {
  content: "";
  background-image: url(../../assets/img/ic-acc-drop.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 24px;
  height: 24px;
  display: block;
  transition: ease-in-out 0.3s;
  position: absolute;
  inset-inline-end: 54px;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.accordion__container[open] .accordion__question::after {
  transform: translateY(-50%) rotate(90deg);
  transition: ease-in-out 0.3s;
}

.accordion__container[open] .accordion__question {
  color: #FE6238;
  transition: ease-in-out 0.3s;
}

.accordion__answer {
  padding: 24px 54px;
  color: #8B8B8B;
}

@media (max-width: 991px) {
  .faq__tabs {
    justify-content: center;
  }
}
.news-hero {
  background-image: url(../../assets/img/news-hero.png);
}

.news {
  padding: 64px 0;
}

.news__title {
  text-align: center;
}

.news__list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-block-start: 54px;
  gap: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.news__item {
  backdrop-filter: blur(7px);
  box-shadow: -20px -20px 40px 0 rgba(118, 251, 237, 0.05), 20px 20px 20px 0 rgba(14, 199, 179, 0.05);
  background-color: rgba(255, 255, 255, 0.28);
  border-radius: 64px;
  overflow: hidden;
  display: flex;
}

.news__item-img {
  min-width: 200px;
  max-width: 200px;
}

.news__item-img > img {
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.news__content {
  position: relative;
  padding: 24px;
  padding-block-start: 80px;
  padding-block-end: 36px;
}

.news__data {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #FE6238;
  border-left: none;
  border-radius: 0 64px 64px 0;
  color: #FE6238;
  padding: 15px 35px;
}

.news__tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #FE6238;
  color: #ffffff;
  padding: 15px 60px;
  margin: 0;
  border-radius: 0 64px;
}

.news__desc {
  margin-block-end: 24px;
}

.pagination {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-block-start: 32px;
}

.pagination__button {
  border-radius: 50%;
  color: #223331;
  width: 40px;
  height: 40px;
  min-height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  padding: 0;
  font-weight: 300;
  padding-block-start: 3px;
}

.pagination__button:hover {
  transform: none;
}

.pagination__button--is-active {
  background-color: #FE6238;
}

@media (max-width: 1200px) {
  .news__list {
    grid-template-columns: minmax(0, 592px);
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .news__item-img {
    min-width: 120px;
    max-width: 120px;
  }
}
@media (max-width: 480px) {
  .news__tag {
    padding: 10px 38px;
    padding-inline-start: 30px;
  }

  .news__data {
    padding: 10px 18px;
  }
}
.footer {
  backdrop-filter: blur(7px);
  box-shadow: -20px -20px 40px 0 rgba(118, 251, 237, 0.05), 20px 20px 20px 0 rgba(14, 199, 179, 0.05);
  background: rgba(255, 255, 255, 0.28);
  border-radius: 64px 64px 0 0;
  padding: 32px 0;
}

.footer__docs-list {
  padding: 0;
  margin: 0;
}

.footer__docs-link {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #8B8B8B;
  font-weight: 500;
}

.footer__wrapper,
.footer__docs {
  display: flex;
  column-gap: 77px;
  row-gap: 30px;
  flex-wrap: wrap;
}

.footer__wrapper .third-title {
  margin-block-end: 12px;
}

.footer__alert-wrapper p {
  font-size: 16px;
  max-width: 590px;
}

.tns-outer {
  padding: 0 !important;
}
.tns-outer [hidden] {
  display: none !important;
}
.tns-outer [aria-controls], .tns-outer [data-action] {
  cursor: pointer;
}

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s;
}
.tns-slider > .tns-item {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tns-horizontal.tns-subpixel {
  white-space: nowrap;
}
.tns-horizontal.tns-subpixel > .tns-item {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
}
.tns-horizontal.tns-no-subpixel:after {
  content: "";
  display: table;
  clear: both;
}
.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left;
}
.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%;
}

.tns-no-calc {
  position: relative;
  left: 0;
}

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px;
}
.tns-gallery > .tns-item {
  position: absolute;
  left: -100%;
  -webkit-transition: transform 0s, opacity 0s;
  -moz-transition: transform 0s, opacity 0s;
  transition: transform 0s, opacity 0s;
}
.tns-gallery > .tns-slide-active {
  position: relative;
  left: auto !important;
}
.tns-gallery > .tns-moving {
  -webkit-transition: all 0.25s;
  -moz-transition: all 0.25s;
  transition: all 0.25s;
}

.tns-autowidth {
  display: inline-block;
}

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6;
}
.tns-lazy-img.tns-complete {
  opacity: 1;
}

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s;
}

.tns-ovh {
  overflow: hidden;
}

.tns-visually-hidden {
  position: absolute;
  left: -10000em;
}

.tns-transparent {
  opacity: 0;
  visibility: hidden;
}

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0;
}

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1;
}

.tns-vpfix {
  white-space: nowrap;
}
.tns-vpfix > div, .tns-vpfix > li {
  display: inline-block;
}

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden;
}
.tns-t-ct {
  width: 2333.3333333333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0;
}
.tns-t-ct:after {
  content: "";
  display: table;
  clear: both;
}
.tns-t-ct > div {
  width: 1.4285714286%;
  width: -webkit-calc(100% / 70);
  width: -moz-calc(100% / 70);
  width: calc(100% / 70);
  height: 10px;
  float: left;
}