//.overlay {
//  position: fixed;
//  top: 0;
//  bottom: 0;
//  left: 0;
//  right: 0;
//  z-index: 999;
//  visibility: hidden;
//  opacity: 0;
//  transition: $transition_base;
//  background: rgba(48, 48, 48, 0.5);
//  backdrop-filter: blur(7.5px);
//}
//
//.modal {
//  position: fixed;
//  top: 50%;
//  left: 50%;
//  transform: translate(-50%, -50%);
//  z-index: 9999;
//  padding: 10vh 15px 5vh 15px;
//  overflow-y: auto;
//  height: 100vh;
//  width: 100vw;
//  scrollbar-color: transparent;
//  scrollbar-width: none;
//  visibility: hidden;
//  opacity: 0;
//  transition: $transition_base;
//  display: none;
//  justify-items: center;
//  align-items: center;
//}
//
//.modal::-webkit-scrollbar {
//  width: 0;
//  height: 0;
//  background-color: transparent;
//}
//
//.modal::-webkit-scrollbar-thumb {
//  background-color: transparent;
//  border-radius: 0;
//  box-shadow: none;
//}
//
//.modal::-webkit-scrollbar-thumb:hover {
//  background-color: transparent;
//}
//
//.modal::-webkit-scrollbar-button:vertical:start:decrement,
//.modal::-webkit-scrollbar-button:vertical:end:increment,
//.modal::-webkit-scrollbar-button:horizontal:start:decrement,
//.modal::-webkit-scrollbar-button:horizontal:end:increment {
//  background: none;
//  visibility: hidden;
//}
//
//.modal__title {
//  text-align: center;
//}
//
//.modal__dialog {
//  max-width: 544px;
//  width: 100%;
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  position: relative;
//  //background-color: $red_color;
//  padding: 32px;
//  border-radius: 20px;
//  //box-shadow: $shadow_main;
//}
//
//.modal__dialog .help__form {
//  padding: 0;
//  justify-content: center;
//  flex-direction: column;
//}
//
//.modal__dialog .help__form .button {
//  align-self: flex-end;
//}

//.close {
//  margin: 0;
//  padding: 0;
//  cursor: pointer;
//  border: none;
//  background-color: transparent;
//  width: 24px;
//  height: 24px;
//  background-image: url(../../assets/img/ic-close.svg);
//  position: absolute;
//  right: 14px;
//  top: 14px;
//}

.is-visible {
  visibility: visible;
  opacity: 1;
  transition: $transition_base;
  display: grid;
}

//.alert {
//  position: fixed;
//  right: 50px;
//  bottom: 50px;
//  box-shadow: 0px 6px 12px rgba(50, 66, 74, 0.1);
//  border-radius: 4px;
//  padding: 24px;
//  width: 345px;
//  visibility: hidden;
//  opacity: 0;
//  transition: $transition_base;
//  transform: translateY(100%);
//  z-index: 9999;
//}
//
//.alert--visible {
//  visibility: visible;
//  opacity: 1;
//  transform: translateY(0);
//  transition: $transition_base;
//}
//
//.alert--error {
//  background-color: darken($yellow_color, 30%);
//}
//
//.alert--success {
//  background-color: darken($yellow_color, 10%);
//}
//
//.alert__close-button {
//  background-color: transparent;
//  border: none;
//  width: 24px;
//  height: 24px;
//  background-image: url(../../assets/img/ic-close-modal.svg);
//  background-size: contain;
//  position: absolute;
//  right: 24px;
//  top: 27px;
//  cursor: pointer;
//}
//
//.alert__text {
//  padding-right: 24px;
//}
//
//.alert__title,
//.alert__text {
//  padding-left: 40px;
//  position: relative;
//}
//
//.alert__title::before {
//  content: '';
//  position: absolute;
//  left: 0;
//  top: 3px;
//  width: 24px;
//  height: 24px;
//  background-size: contain;
//  background-repeat: no-repeat;
//}
//
//.alert--error .alert__title::before {
//  background-image: url(../../assets/img/ic-alert.svg);
//}
//
//.alert--success .alert__title::before {
//  background-image: url(../../assets/img/ic-check.svg);
//}
//
//
////MEDIA
//@media (max-width: 576px) {
//  .modal__subtitle {
//    margin-left: 0;
//    margin-right: 0;
//  }
//}
//
//@media (max-width: 480px) {
//  .modal__dialog {
//    padding: 25px 20px;
//    max-width: 100%;
//    width: 90vw;
//  }
//
//  .alert {
//    right: 50%;
//    transform: translateX(50%) translateY(100%);
//  }
//
//  .alert--visible {
//    transform: translateX(50%) translateY(0);
//  }
//}
