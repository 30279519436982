.footer {
  backdrop-filter: blur(7px);
  box-shadow: -20px -20px 40px 0 rgba(118, 251, 237, 0.05), 20px 20px 20px 0 rgba(14, 199, 179, 0.05);
  background: rgba(255, 255, 255, 0.28);
  border-radius: 64px 64px 0 0;
  padding: 32px 0;
}

.footer__docs-list {
  padding: 0;
  margin: 0;
}

.footer__docs-link {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: $grey_color;
  font-weight: 500;
}

.footer__wrapper,
.footer__docs {
  display: flex;
  column-gap: 77px;
  row-gap: 30px;
  flex-wrap: wrap;
}

.footer__wrapper .third-title {
  margin-block-end: 12px;
}

.footer__alert-wrapper p {
  font-size: 16px;
  max-width: 590px;
}
