.hero {
  background-image: url(../../assets/img/home-hero.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  min-height: 800px;
  position: relative;
}

.hero__content {
  position: absolute;
  inset-block-start: 50%;
  transform: translateY(-50%);
  max-width: 510px;
}

.hero__desc {
  margin: 24px 0;
  color: $black_color;
}

.hero__content-actions {
  display: flex;
  gap: 24px;
}

@media (max-width: 1199px) {
  .hero {
    background-size: 40%;
    background-position: right center;
  }
}

@media (max-width: 768px) {
  .hero {
    min-height: 500px;
    background-position: 125% 60%;
  }
}

@media (max-width: 576px) {
  section.hero {
    //min-height: 600px;
    //background-position: 200% 115%;
    //background-size: 70%;
    background-image: none;
    padding: 120px 0;
    padding-block-start: 180px;
  }

  .hero__content {
    //max-width: 300px;
    position: static;
    transform: none;
    text-align: center;
    width: 100%;
  }

  .hero__content-actions {
    gap: 10px;
    flex-direction: column;
  }
}
